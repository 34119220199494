.bottom_container {
  padding-top: 18px;
  width: 750px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  font-family: "Open Sans", sans-serif !important;
  .btn_color {
    font-family: "Open Sans", sans-serif !important;
    background: #31edb3 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    width: 114px;
    height: 54px;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #002c69;
  }
  @media only screen and (max-width: 600px) {
    width: auto !important;
    min-width: unset !important;
  }
}
