.final-container {
  background: #002c69 0% 0% no-repeat padding-box;
  border-radius: 32px;
  opacity: 1;
  width: 391px;
  height: 517px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 17px;

  .fl-1 {
    flex: 0.3;
    position: absolute;
    top: -39px;
  }
  .chat_avatar {
    width: 120px;
    border: 8px solid #ff675b;
    border-radius: 50%;
  }
  .content {
    padding-top: 110px;
    flex-direction: column;
    .congates_img {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .description {
      text-align: center;
      font-size: 16px;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      padding: 19px 47px;
    }
    .submit_button {
      background: #31edb3 0% 0% no-repeat padding-box;
      border-radius: 16px;
    }
    .btn {
      text-align: center;
    }
    .p-30 {
      padding-top: 30px;
    }
    .btn-center {
      gap: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .btn_color {
      font-family: "Open Sans", sans-serif !important;
      background: #31edb3 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      opacity: 1;
      width: 260px;
      height: 54px;
      border-radius: 12px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #002c69;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.header_container_final {
  width: 391px !important;
  padding-bottom: 12px !important;
  .logo_head {
    padding-right: unset !important;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
