.header_container {
  width: 600px;

  .fl-1 {
    flex: 1.1;
  }
  .fl-2 {
    flex: 4;
  }
  .logo_head {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-right: 35px;
    justify-content: flex-end;
    .poweredby {
      color: #002c69;
      font-family: "Open Sans";
      font-size: 12px;
    }
  }
  .chat_info {
    display: flex;
    align-items: center;
    .chat_avatar {
      width: 95%;
      border: 8px solid #ff675b;
      border-radius: 50%;
    }
    .img_chat_layer {
      width: 100%;
    }
    .position-re {
      position: relative;
    }
    .chat-text {
      // font-size: calc(7px + 1.5vmin);
      font-size: 14px;
      opacity: 1;
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      letter-spacing: 0px;
      color: #ffffff;
      text-align: start;
      @media only screen and (max-width: 600px) {
        font-size: 9px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
