.petitioner_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #002c69;
  opacity: 1;
  width: 750px;
  border-radius: 18px;
  padding: 20px 54px;
  padding-bottom: 30px;
  @media only screen and (max-width: 600px) {
    padding: 10px 10px !important;
  }
  .header {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #002c69;
    opacity: 1;
    text-align: center;
    padding-bottom: 15px;
    @media only screen and (max-width: 600px) {
      padding-bottom: 8px;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 100% !important;
    min-width: unset !important;
  }
}
