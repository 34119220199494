.premium_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #002c69;
  opacity: 1;
  width: 840px;
  border-radius: 18px;
  padding: 20px 54px;
  @media only screen and (max-width: 600px) {
    padding: 20px 10px;
  }
  .header {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #002c69;
    opacity: 1;
    text-align: center;
    padding-bottom: 10px;
  }
  .button_container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding-bottom: 10px;
    .btn_color {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 16px;
      opacity: 1;
      width: 114px;
      height: 54px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #002c69;
    }
    @media only screen and (max-width: 600px) {
      gap: 20px !important;
    }
  }
  .info {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #727272;
    width: 90%;
    padding: 4px 12px;
  }
  @media only screen and (max-width: 600px) {
    width: 100% !important;
    min-width: unset !important;
  }
}
