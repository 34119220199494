@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

body {
  font-family: "Open Sans", sans-serif !important;
}
.App {
  // text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  padding: 15px 22px;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-tel-input {
  .form-control {
    height: 45px !important;
    border: 1.5px solid #002c69 !important;
    border-radius: 16px !important;
    width: 100% !important;
  }
  .selected-flag {
    padding: 0 0 0 14px !important;
    width: 46px !important;
  }
  .flag-dropdown {
    background-color: #ffffff !important;
    border: 1.5px solid #002c69 !important;
    border-radius: 13px 1px 1px 16px !important;
  }
}
.custom-label {
  font-size: 14px !important;
  margin-bottom: 8px !important;
  letter-spacing: 0px;
  color: #001026 !important;
  font-weight: 600 !important;
  padding-left: 15px !important;
}

.custom-input {
  width: 100%;

  .MuiOutlinedInput-root {
    height: 35px !important;
    width: 100%;
    background-color: #ffffff !important;
    border: 1.5px solid #002c69;
    border-radius: 16px !important;
    height: 48px !important;
    padding: 1px 16px;
    font-size: 16px !important;
    color: #002c69 !important;
    font-weight: 600 !important;
    ::placeholder {
      font-size: 14px !important;
      color: #d5d5d5 !important;
      font-weight: 600 !important;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12 -18 */
      font-size: 14px !important;
      color: #d5d5d5 !important;
      font-weight: 600 !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
    top: 0px !important;
  }

  input {
    font-size: 16px !important;
    color: #002c69 !important;
    font-weight: 600 !important;
    padding: 6.2px 14px !important;
    border-radius: 5px;
    // &::placeholder {
    //   color: #002c69 !important;
    //   font-weight: 700 !important;
    // }
    &.Mui-disabled {
      background-color: hsl(0, 0%, 95%);
    }
  }

  .MuiFormHelperText-root {
    color: rgb(255, 0, 0);
    margin-left: 12px;
  }
}
.custom-textarea {
  width: 100%;
  .Mui-disabled {
    background-color: lightgrey !important;
  }

  .MuiOutlinedInput-root {
    // background-color: #ffffff;
    // border: 1px solid #cccccc !important;
    // border-radius: 5px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #002c69 !important;
    border-radius: 16px !important;
    opacity: 1;
    padding: 7px 14px;
    font-size: 16px;
    color: #002c69;
    font-weight: 600;
    ::placeholder {
      font-size: 14px !important;
      color: #d5d5d5 !important;
      font-weight: 600 !important;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12 -18 */
      font-size: 14px !important;
      color: #d5d5d5 !important;
      font-weight: 600 !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: unset !important;
  }

  textarea {
    font-size: 16px;
    color: #002c69;
    font-weight: 600;
    padding: 2px 14px !important;
  }
  .MuiFormHelperText-root {
    color: rgb(255, 0, 0);
    margin-left: 12px;
  }
}
