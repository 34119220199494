.main_content {
  min-width: 575px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #002c69;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 18px;
  padding: 35px 12px;

  .heading {
    text-align: center;
    letter-spacing: 0px;
    color: #002c69;
    width: 480px;
    margin: auto;
    // font-size: calc(9px + 1vmin);
    font-size: 20px;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
      width: 100%;
    }
  }
  .btn-content {
    height: 54px;
    min-width: 230px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 16px;
    opacity: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    margin: auto;
    color: #002c69;
    font-weight: 600;
    text-align: center;
    // font-size: calc(7px + 1vmin);
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
  .m-w-450 {
    min-width: 450px;
    @media only screen and (max-width: 600px) {
      min-width: 100%;
      width: 100%;
    }
  }

  .w-303 {
    width: 303px;
  }

  @media only screen and (max-width: 600px) {
    width: 100% !important;
    min-width: unset !important;
    gap: 20px !important;
  }
}

.back-btn {
  text-align: center;
  font-size: 16px;
  // margin: auto;
  // color: #7d7d7d;
  font-weight: 600;
  // cursor: pointer;
  background: #31edb3 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  width: 114px;
  height: 54px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #002c69;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
}
